<template>
  <cs-page class="views-learn-details mx-auto">
    <div class="d-flex learn-nav">
      <span style="width: 80px; color: #949898">当前分类：</span>
      <a-breadcrumb separator=">" style="flex: 1">
        <a-breadcrumb-item
          v-for="(item, index) in source.nav"
          :key="index"
          style="color: #949898"
          >{{ item }}</a-breadcrumb-item
        >
      </a-breadcrumb>
      <a
        :href="formModel.content"
        v-if="formModel.type == 1"
        target="_blank"
        style="margin-right: 360px; color: #333"
        >全屏预览</a
      >
    </div>
    <div class="d-flex mx-auto">
      <div class="flex-fill content-panel">
        <iframe
          :src="formModel.url"
          allowfullscreen="true"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          style="width: 100%; min-height: 680px; border: none"
        ></iframe>
      </div>
      <div style="width: 340px">
        <div class="learn-card">
          <h3>正在学习</h3>
          <a
            href="javascript:;"
            class="d-flex justify-content-between"
            style="padding-bottom: 18px"
          >
            <img :src="formModel.cover" class="imgs" />
            <p class="text flex-fill elliplis3" style="width: 172px">
              {{ formModel.title }}
            </p>
          </a>
        </div>
        <div
          class="learn-card"
          style="
            margin-top: 20px;
            height: 503px;
            overflow:hidden;
          "
          v-if="source.recommList.length"
        >
          <h3>推荐学习</h3>
          <div style="overflow-y: auto;height:430px;">
             <a
              :href="`?id=${item.id}`"
              style="margin-bottom: 20px"
              target="_blank"
              v-for="(item, index) in source.recommList"
              :key="index"
            >
              <img :src="item.cover" class="imgs" />
              <div style="margin-left: 126px; height: 66px">
                <p class="text flex-fill elliplis2" style="width: 160px">
                  {{ item.title }}
                </p>
                <span
                  class="tags"
                  :class="item.type === 1 ? 'c-yellow' : 'c-blue'"
                  >{{ item.type === 1 ? "#文档" : "#视频" }}</span
                >
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </cs-page>
</template>
<script>
export default {
  data() {
    return {
      formModel: {
        id: "",
        content: "",
        title: "",
        cover: "",
        type: "",
        url: "",
      },
      source: {
        nav: [],
        recommList: [],
      },
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      const { id } = this.$route.query;
      if (!id) {
        return this.$router.replace("/learn/index");
      }
      this.formModel.id = id;
      this.api.service
        .fpc_oapi_study_file_getStudyFileById({ id })
        .then(async ({ code, data, msg }) => {
          await this.api.toast({ code, msg }, 0);
          console.log(data);
          this.source.nav = data.organList.map((ele) => ele.name);
          this.source.recommList = data.recommList;
          this.formModel.title = data.title;
          this.formModel.content = data.content;
          this.formModel.type = data.type;
          let url = data.content;
          // 文档格式化显示
          if (data.type == 1) {
            url += "#toolbar=0&view=FitH,top";
          }
          this.formModel.url = url;
          this.formModel.cover = data.cover;
        });
    },
  },
};
</script>
<style lang="less" scoped>
.views-learn-details {
  width: 1200px;
  .learn-nav {
    padding-top: 60px;
    padding-bottom: 20px;
  }
  .content-panel {
    margin-right: 20px;
  }
  .learn-card {
    background: #ffffff;
    border-radius: 10px;
    padding: 0 20px;
    h3 {
      font-family: PingFangSC-Medium;
      font-size: 20px;
      color: #202121;
      letter-spacing: 0;
      border-bottom: 1px solid #e5e5e5;
      line-height: 55px;
      font-weight: 500;
      margin-bottom: 20px;
    }
    .tags {
      font-family: PingFangSC-Regular;
      font-size: 12px;
      line-height: 12px;
      margin-top: 5px;
      display: inline-block;
      width: 48px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      &.c-yellow {
        background: #fcf4e7;
        color: #e59317;
      }
      &.c-blue {
        background: #e9f4fd;
        color: #2b94ed;
      }
    }
    a {
      margin-bottom: 20px;
      width: 100%;
      display: block;
    }
    .imgs {
      width: 116px;
      height: 66px;
      margin-right: 12px;
      float: left;
    }
    .text {
      font-size: 14px;
      color: #202121;
      text-align: justify;
      letter-spacing: 0.6px;
      margin: 0;
      transition: color 0.3s;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      &.elliplis2 {
        -webkit-line-clamp: 2;
        height: 40px;
      }
      &.elliplis3 {
        -webkit-line-clamp: 3;
      }
      &:hover {
        color: #00a497;
      }
    }
  }
}
</style>